import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./data-science.module.scss"
import DataSIllustration from "../../assets/images/datas-illustration.svg"
import Button from "../../components/Button/Button"
import { projectsList, servicesList } from "../../hooks"
import { useProjectsCardsThumbnails } from "../../hooks/useProjectsCardsThumbnails"
import services from "../services.module.scss"
import PresentationForm from "../../components/PresentationForm/PresentationForm"

const expertiseItems = [
  {
    numPath: "services.data-science.expertise-block.first-item.num",
    textPath: "services.data-science.expertise-block.first-item.text",
  },
  {
    numPath: "services.data-science.expertise-block.second-item.num",
    textPath: "services.data-science.expertise-block.second-item.text",
  },
  {
    numPath: "services.data-science.expertise-block.third-item.num",
    textPath: "services.data-science.expertise-block.third-item.text",
  },
]

const tasksListItems = [
  "services.data-science.tasks-block.list.first",
  "services.data-science.tasks-block.list.second",
  "services.data-science.tasks-block.list.third",
  "services.data-science.tasks-block.list.forth",
  "services.data-science.tasks-block.list.fifth",
  "services.data-science.tasks-block.list.sixth",
]

const DataScience: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useProjectsCardsThumbnails()
  const serviceList = servicesList
  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const dataScienceClasses = classNames(
    componentStyles.dataScience,
    "container"
  )
  const taskClasses = classNames(
    componentStyles.dataScienceTasksBlockLastBlock,
    "grid-2"
  )
  const expertiseClasses = classNames(
    componentStyles.dataScienceExpertiseBlockList,
    "grid-3"
  )
  const dataScienceTasksBlockList = classNames(
    componentStyles.dataScienceTasksBlockList,
    "grid-4"
  )
  const projectsClasses = classNames(
    componentStyles.dataScienceProjects,
    "container"
  )
  const projectsListClasses = classNames(
    componentStyles.dataScienceProjectsList,
    "grid-2"
  )
  const projectsTitleClasses = classNames(
    componentStyles.dataScienceProjectsTitle,
    "large-section-title"
  )
  const blockInServices = classNames(services.blockInServices, "container")
  const blockInServicesList = classNames(services.blockInServicesList, "grid-3")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "services.data-science.seo.description",
        })}
        title={intl.formatMessage({
          id: "services.data-science.seo.title",
        })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({
              id: "services.data-science.title",
            })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageMainImage}
              src={DataSIllustration}
              alt="Illustration with dashboards, people and data blocks flowing"
              width="488"
              height="437"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({
                id: "services.data-science.banner-text",
              })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={dataScienceClasses}>
        <div className={componentStyles.dataScienceExpertiseBlock}>
          <h4>
            {intl.formatMessage({
              id: "services.data-science.expertise-block.title",
            })}
          </h4>
        </div>
        <div className={expertiseClasses}>
          {expertiseItems.map(({ numPath, textPath }) => (
            <div
              key={textPath}
              className={componentStyles.dataScienceExpertiseBlockListItem}
            >
              <span
                className={componentStyles.dataScienceExpertiseBlockListItemNum}
              >
                {intl.formatMessage({
                  id: numPath,
                })}
              </span>
              <p
                className={
                  componentStyles.dataScienceExpertiseBlockListItemText
                }
              >
                {intl.formatMessage({
                  id: textPath,
                })}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className={dataScienceClasses}>
        <div className={componentStyles.dataScienceTasksBlock}>
          <h4>
            {intl.formatMessage({
              id: "services.data-science.tasks-block.title",
            })}
          </h4>
          <div className={dataScienceTasksBlockList}>
            {tasksListItems.map(taskPath => (
              <div
                key={taskPath}
                className={componentStyles.dataScienceTasksBlockListItem}
              >
                <p>
                  {intl.formatMessage({
                    id: taskPath,
                  })}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={taskClasses}>
          <div className={componentStyles.dataScienceTasksBlockLastBlockText}>
            {intl.formatMessage({
              id: "services.data-science.tasks-block.text",
            })}
          </div>
          <div>
            <img
              className={componentStyles.dataScienceTasksBlockLastBlockImgLeft}
              src="/images/cube-data.svg"
              alt="Orange graphic cube image"
              width="33"
              height="42"
            />
            <img
              className={
                componentStyles.dataScienceTasksBlockLastBlockImgMiddle
              }
              src="/images/block-illustration.svg"
              alt="Orange and grey graphic cubes image"
              width="142"
              height="159"
            />
            <img
              className={componentStyles.dataScienceTasksBlockLastBlockImgRight}
              src="/images/block2-illustration.svg"
              alt="Orange graphic cube image"
              width="135"
              height="99"
            />
          </div>
        </div>
      </section>
      <section className={blockInServices}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.services.top-title" })}
        </h2>
        <ul className={blockInServicesList}>
          {serviceList.map(({ title, link }) => {
            const path = "/services/" + link
            if (
              "/" + intl.locale + path !== location.pathname &&
              "/" + intl.locale + path + "/" !== location.pathname
            ) {
              return (
                <li key={title} className={services.blockInServicesListItem}>
                  <h3 className={services.blockInServicesListItemTitle}>
                    {intl.formatMessage({ id: title })}
                  </h3>
                  <Button
                    to={path}
                    className={services.blockInServicesListButton}
                  >
                    {intl.formatMessage({ id: "services.button-title" })}
                  </Button>
                </li>
              )
            }
          })}
        </ul>
      </section>
    </Layout>
  )
}
export default DataScience
